.faq {
  width: 1400px;
  padding: 2rem;
  display: flex;
  justify-content: center;

  .faq__container {
    width: 100%;
    padding: 1vw;

    .faq__header {
      padding: 2rem 0;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .faq {
    width: 100%;
    padding: 0.5rem;
    .faq__container {
      .faq__header {
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .faq {
    width: 100%;
    .faq__container {
      .faq__header {
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .faq {
    width: 100%;
    .faq__container {
      .faq__header {
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .faq {
    width: 100%;
    .faq__container {
      .faq__header {
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .faq {
    width: 1400px;
    .faq__container {
      .faq__header {
      }
    }
  }
}




