.apartment-selector {
  padding: 2rem;
  display: flex;
  justify-content: center;

  .apartment-selector__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;

    .apartment-selector__apartments {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .apartment-selector__option {
        width: 45%;
        position: relative;

        .apartment-selector__image {
          width: 100%;

          img{
            width: 100%;
            border-radius: 50%;
          }
        }

        .apartment-selector__label {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: rgba(0,0,0,0.20);
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 5rem;
          color: #FFFFFF;
          cursor: pointer;
          font-family: 'SeaweedScript';
        }

        .apartment-selector__label:hover {
          @extend .apartment-selector__label;

          background-color: rgba(0,0,0,0.40);
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .apartment-selector {
    width: 100%;
    .apartment-selector__container {
      .apartment-selector__apartments {
        .apartment-selector__option {
          width: 100%;
          margin: 0.5rem 0;
          .apartment-selector__image {
            img{
            }
          }

          .apartment-selector__label {
          }

          .apartment-selector__label:hover {
          }
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .apartment-selector {
    width: 100%;
    .apartment-selector__container {
      .apartment-selector__apartments {
        .apartment-selector__option {
          .apartment-selector__image {
            img{
            }
          }

          .apartment-selector__label {
          }

          .apartment-selector__label:hover {
          }
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .apartment-selector {
    width: 100%;
    .apartment-selector__container {
      .apartment-selector__apartments {
        .apartment-selector__option {
          .apartment-selector__image {
            img{
            }
          }

          .apartment-selector__label {
          }

          .apartment-selector__label:hover {
          }
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .apartment-selector {
    width: 100%;
    .apartment-selector__container {
      .apartment-selector__apartments {
        .apartment-selector__option {
          .apartment-selector__image {
            img{
            }
          }

          .apartment-selector__label {
          }

          .apartment-selector__label:hover {
          }
        }
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .apartment-selector {
    width: 1400px;
    .apartment-selector__container {
      .apartment-selector__apartments {
        .apartment-selector__option {
          .apartment-selector__image {
            img{
            }
          }
          .apartment-selector__label {
          }
          .apartment-selector__label:hover {
          }
        }
      }
    }
  }
}






