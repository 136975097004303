.contact {
  display: flex;
  justify-content: center;
  padding: 2rem;

  .contact__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .contact__view {
      width: 50%;

      .contact__view__header {
        text-align: center;
      }

      .contact__view__content {
        img {
          width: 100%;
        }
      }
    }

    .contact__data {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 2rem;
      padding: 1rem;
    }

    .contact__data-container__half-view {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .contact__data-container__full-view {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .contact {
    width: 100%;
    .contact__container {
      .contact__view {
        width: 100%;
        .contact__view__header {
        }
        .contact__view__content {
          img {
          }
        }
      }
      .contact__data {
        font-size: 1.5rem;
      }
      .contact__data-container__half-view {
        width: 100%;
      }
      .contact__data-container__full-view {
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .contact {
    width: 100%;
    .contact__container {
      .contact__view {
        width: 100%;
        .contact__view__header {
        }
        .contact__view__content {
          img {
          }
        }
      }
      .contact__data {
      }
      .contact__data-container__half-view {
        width: 100%;
        padding: 3rem;
      }
      .contact__data-container__full-view {
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .contact {
    width: 100%;
    .contact__container {
      .contact__view {
        width: 100%;
        .contact__view__header {
        }
        .contact__view__content {
          img {
          }
        }
      }
      .contact__data {
      }
      .contact__data-container__half-view {
        width: 100%;
        padding: 3rem;
      }
      .contact__data-container__full-view {
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .contact {
    width: 100%;
    .contact__container {
      .contact__view {
        width: 50%;
        .contact__view__header {
          visibility: hidden;
        }
        .contact__view__content {
          img {
          }
        }
      }
      .contact__data {
      }
      .contact__data-container__half-view {
        width: 50%;
      }
      .contact__data-container__full-view {
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .contact {
    width: 1400px;
    .contact__container {
      .contact__view {
        width: 50%;
        .contact__view__header {
          visibility: hidden;
        }
        .contact__view__content {
          img {
          }
        }
      }
      .contact__data {
      }
      .contact__data-container__half-view {
        width: 50%;
      }
      .contact__data-container__full-view {
      }
    }
  }
}

