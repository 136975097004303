.footer {
  width: 100%;
  background-color: #004aad;
  height: 3rem;
  display: flex;
  justify-content: center;
}

.footer__container {
  width: 70%;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__item {

}
