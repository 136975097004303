.description {
  width: 1400px;
  padding: 2rem;
  display: flex;
  justify-content: center;

  .description__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .description__section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      // TODO możliwe że do usunięcia
      .description__buzzwords {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        background-color: #FFFFFF;
        width: 100%;
        padding: 2rem;
      }

      // TODO możliwe że do usunięcia
      .description__buzzwords-value {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        padding: 1rem;
        border-bottom: 2px solid #004aad;
        font-size: 2rem;
      }

      // TODO możliwe że do usunięcia
      .description__buzzwords-text {
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        width: 100%;
        padding: 1rem;
        font-size: 1.5rem;
      }

      .description__equipments {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: initial;

        .description__equipment-header {
          padding: 2rem;
          font-size: 3rem;
        }

        .description__equipment-container {
          width: 100%;
          padding: 0 1rem;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .description__equipment-cell {
            width: 33%;
            height: 8vw;
            display: flex;
            flex-direction: row;

            .description__equipment-icon {
              width: 30%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                height: 60%;
                width: 60%;
              }
            }

            .description__equipment-label {
              height: 100%;
              width: 70%;
              display: flex;
              padding: 2rem;
              text-align: center;
              justify-content: center;
              align-items: center;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .description {
    width: 100%;
    padding: 0.5rem;
    .description__container {
      .description__section {
        .description__buzzwords {
        }
        .description__buzzwords-value {
        }
        .description__buzzwords-text {
        }

        .description__equipments {
          .description__equipment-header {
          }
          .description__equipment-container {
            .description__equipment-cell {
              width: 100%;
              height: 20vw;
              margin: 1rem 0;
              .description__equipment-icon {
                img {
                }
              }
              .description__equipment-label {
              }
            }
          }
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .description {
    width: 100%;
    .description__container {
      .description__section {
        .description__buzzwords {
        }
        .description__buzzwords-value {
        }
        .description__buzzwords-text {
        }

        .description__equipments {
          .description__equipment-header {
          }
          .description__equipment-container {
            .description__equipment-cell {
              width: 100%;
              .description__equipment-icon {
                img {
                  height: 60%;
                  width: auto;
                }
              }
              .description__equipment-label {
              }
            }
          }
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .description {
    width: 100%;
    .description__container {
      .description__section {
        .description__buzzwords {
        }
        .description__buzzwords-value {
        }
        .description__buzzwords-text {
        }

        .description__equipments {
          .description__equipment-header {
          }
          .description__equipment-container {
            .description__equipment-cell {
              width: 50%;
              .description__equipment-icon {
                img {
                  height: 60%;
                  width: auto;
                }
              }
              .description__equipment-label {
                font-size: 0.8rem;
                padding: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .description {
    width: 992px;
    .description__container {
      .description__section {
        .description__buzzwords {
        }
        .description__buzzwords-value {
        }
        .description__buzzwords-text {
        }

        .description__equipments {
          .description__equipment-header {
          }
          .description__equipment-container {
            .description__equipment-cell {
              width: 50%;
              .description__equipment-icon {
                img {
                  height: 60%;
                  width: auto;
                }
              }
              .description__equipment-label {
                font-size: 1rem;
                padding: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .description {
    width: 1400px;
    .description__container {
      .description__section {
        .description__buzzwords {
        }
        .description__buzzwords-value {
        }
        .description__buzzwords-text {
        }

        .description__equipments {
          .description__equipment-header {
          }
          .description__equipment-container {
            .description__equipment-cell {
              width: 33%;
              .description__equipment-icon {
                img {
                  height: 60%;
                  width: 60%;
                }
              }
              .description__equipment-label {
              }
            }
          }
        }
      }
    }
  }
}




