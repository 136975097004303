.map {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .map__header {
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
  }

  .map__locations {
    width: 50%;

    .map__locations__header {
      width: 100%;
      padding: 1rem;
      text-align: center;
    }

    .map__locations__content {
      width: 100%;
    }
  }

  .map__container {
    width: 50%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .map {
    width: 100%;
    padding: 0.5rem;
    .map__header {
    }
    .map__locations {
      width: 100%;
      .map__locations__header {
      }
      .map__locations__content {
      }
    }
    .map__container {
      width: 100%;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .map {
    width: 100%;
    .map__header {
    }
    .map__locations {
      width: 100%;
      .map__locations__header {
      }
      .map__locations__content {
      }
    }
    .map__container {
      width: 100%;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .map {
    width: 100%;
    .map__header {
    }
    .map__locations {
      width: 100%;
      .map__locations__header {
      }
      .map__locations__content {
      }
    }
    .map__container {
      width: 100%;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .map {
    width: 100%;
    .map__header {
    }
    .map__locations {
      width: 50%;
      .map__locations__header {
      }
      .map__locations__content {
      }
    }
    .map__container {
      width: 50%;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .map {
    width: 1400px;
    .map__header {
    }
    .map__locations {
      width: 50%;
      .map__locations__header {
      }
      .map__locations__content {
      }
    }
    .map__container {
      width: 50%;
    }
  }
}




