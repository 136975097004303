@keyframes moving-arrow {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.navbar {
  background-color: #004aad;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #FFFFFF;
  position: sticky;
  top: 0;
  z-index: 20;

  .navbar__container {
    padding: 0 2rem;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .navbar__logo-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: initial;
      align-items: center;

      .navbar__logo {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        display: flex;
        flex-direction: row;

        span {
          padding: 0 0.1rem;
        }

        .navbar__apartment {
          cursor: pointer;
        }

        .navbar__selected-apartment {
          color: gold;
        }
      }

      .navbar__logo-hint {
        width: 100%;
        font-size: 1rem;
        display: flex;
        justify-content: initial;
        align-items: center;
        text-align: center;

        .navbar__logo-hint__arrow {
          margin-left: 1.5rem;
          animation-name: moving-arrow;
          animation-duration: 1.2s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;

          img {
            height: 1.5rem;
            width: 1.5rem;
          }
        }

        .navbar__logo-hint__text {


        }
      }
    }

    .navbar__hamburger-button {
      display: none;
      height: 100%;
      width: 80px;
      cursor: pointer;
      justify-content: center;
      align-items: center;

      :hover {
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.20);
        border-radius: 50%;
      }
    }

    .navbar__menu {
      width: 100%;
      display: flex;
      flex-direction: row;

      ul {
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 0;
        list-style: none;
      }

      .navbar__item {
        line-height: 40px;
        margin-right: 1rem;
        white-space: nowrap;
        cursor: pointer;
        text-align: center;
        font-size: 1.5rem;

        :hover {
          color: gold;
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .navbar {
    height: 60px;

    .navbar__container {
      width: 100%;

      .navbar__logo-container {
        .navbar__logo {
          font-size: 1rem;

          .navbar__apartment {
          }

          .navbar__selected-apartment {
          }
        }

        .navbar__logo-hint {
          .navbar__logo-hint__arrow {
            img {
              height: 1rem;
              width: 1rem;
            }
          }

          .navbar__logo-hint__text {
            font-size: 0.7rem;
            margin: 0 1rem;
          }
        }
      }

      .navbar__hamburger-button {
        display: flex;
      }

      .navbar__menu {
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        display: none;
        z-index: 1000;
        padding: 2rem;

        ul {
          width: 100%;
          flex-direction: column;
          align-items: initial;
        }

        .navbar__item {
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navbar {
    height: 80px;

    .navbar__container {
      width: 100%;

      .navbar__logo-container {
        .navbar__logo {
          .navbar__apartment {
          }

          .navbar__selected-apartment {
          }
        }

        .navbar__logo-hint {
          .navbar__logo-hint__arrow {
            img {
              height: 1rem;
              width: 1rem;
            }
          }

          .navbar__logo-hint__text {
            font-size: 1rem;
          }
        }
      }

      .navbar__hamburger-button {
        display: flex;
      }

      .navbar__menu {
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        display: none;
        z-index: 1000;
        padding: 2rem;

        ul {
          width: 100%;
          flex-direction: column;
          align-items: initial;
        }

        .navbar__item {
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  // Tutaj hamburger
  .navbar {
    font-size: 1rem;

    .navbar__container {
      width: 100%;

      .navbar__logo-container {
        .navbar__logo {
          font-size: 1.5rem;

          .navbar__apartment {
          }

          .navbar__selected-apartment {
          }
        }

        .navbar__logo-hint {
          .navbar__logo-hint__arrow {
            img {
              height: 1.5rem;
              width: 1.5rem;
            }
          }

          .navbar__logo-hint__text {
          }
        }
      }

      .navbar__hamburger-button {
      }

      .navbar__menu {
      }

      .navbar__item {
        font-size: 1rem;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .navbar {
    font-size: 1rem;

    .navbar__container {
      width: 992px;

      .navbar__logo-container {
        .navbar__logo {
          font-size: 1.5rem;

          .navbar__apartment {
          }

          .navbar__selected-apartment {
          }
        }

        .navbar__logo-hint {
          .navbar__logo-hint__arrow {
            img {
              height: 1.5rem;
              width: 1.5rem;
            }
          }

          .navbar__logo-hint__text {
            margin: 0;
          }
        }
      }

      .navbar__hamburger-button {
        display: none;
        width: 0;
      }

      .navbar__menu {
        width: 100%;
        display: flex;
        flex-direction: row;

        position: unset;
        top: unset;
        right: unset;
        height: unset;
        background-color: unset;
        padding: 0;

        ul {
          display: flex;
          flex-direction: row;
          justify-content: end;
          align-items: center;
          text-align: center;
          margin-bottom: 0;
          list-style: none;
        }

        .navbar__item {
          font-size: 1rem;

          :hover {
          }
        }
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .navbar {
    font-size: 1rem;

    .navbar__container {
      width: 1400px;

      .navbar__logo-container {
        .navbar__logo {
          font-size: 1.5rem;

          .navbar__apartment {
          }

          .navbar__selected-apartment {
          }
        }

        .navbar__logo-hint {
          .navbar__logo-hint__arrow {
            img {
              height: 1.5rem;
              width: 1.5rem;
            }
          }

          .navbar__logo-hint__text {
          }
        }
      }

      .navbar__hamburger-button {
        display: none;
        width: 0;
      }

      .navbar__menu {
        ul {
        }

        .navbar__item {
          font-size: 1.5rem;

          :hover {
          }
        }
      }
    }
  }
}


