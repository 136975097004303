@import "styles/global.scss";
@import "bootstrap/dist/css/bootstrap.css";
@import "font-awesome/css/font-awesome.min.css";
@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');

@font-face {
    font-family: "Moontime";
    src: local("Moontime"),
    url("../public/fonts/MoonTime-Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Caveat";
    src: local("Caveat"),
    url("../public/fonts/Caveat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Alkatra";
    src: local("Alkatra"),
    url("../public/fonts/Alkatra-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Lato";
    src: local("Lato"),
    /*url("../public/fonts/Lato-BlackItalic.ttf") format("truetype");*/
    url("../public/fonts/Lato-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
    url("../public/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SeaweedScript";
    src: local("SeaweedScript"),
    url("../public/fonts/SeaweedScript-Regular.ttf") format("truetype");
}
