.introduction {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .introduction__header {

  }

  .introduction__line {
    width: 80%;
    border-top: 2px solid #004aad;
  }

  .introduction__text {
    padding: 2rem 0;
    font-size: 1.5rem;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .introduction {
    width: 100%;
    .introduction__header {
    }
    .introduction__line {
    }
    .introduction__text {
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .introduction {
    width: 100%;
    .introduction__header {
    }
    .introduction__line {
    }
    .introduction__text {
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .introduction {
    width: 100%;
    .introduction__header {
    }
    .introduction__line {
    }
    .introduction__text {
      width: 90%;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .introduction {
    width: 100%;
    .introduction__header {
    }
    .introduction__line {
    }
    .introduction__text {
      width: 90%;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .introduction {
    width: 1400px;
    .introduction__header {
    }
    .introduction__line {
    }
    .introduction__text {
      width: 80%;
    }
  }
}



