.gallery {
  padding: 2rem;
  display: flex;
  justify-content: center;

  .gallery__container {
    width: 100%;

    .gallery__header {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 2rem 0;
    }

    .gallery__images {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      .gallery__image-container {
        display: inline;
        width: 33%;
        padding: 5px;

        .gallery__image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .gallery {
    width: 100%;
    padding: 0.5rem;
    .gallery__container {
      .gallery__header {
      }
      .gallery__images {
        .gallery__image-container {
          width: 100%;
          .gallery__image {
          }
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .gallery {
    width: 100%;
    .gallery__container {
      .gallery__header {
      }
      .gallery__images {
        .gallery__image-container {
          width: 100%;
          .gallery__image {
          }
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .gallery {
    width: 100%;
    .gallery__container {
      .gallery__header {
      }
      .gallery__images {
        .gallery__image-container {
          width: 50%;
          .gallery__image {
          }
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .gallery {
    width: 100%;
    .gallery__container {
      .gallery__header {
      }
      .gallery__images {
        .gallery__image-container {
          width: 25%;
          .gallery__image {
          }
        }
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .gallery {
    width: 1400px;
    .gallery__container {
      .gallery__header {
      }
      .gallery__images {
        .gallery__image-container {
          .gallery__image {
          }
        }
      }
    }
  }
}



