@keyframes moving-arrow {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.main-photos {
  width: 100%;

  .main-photos__container {
    width: 100%;
    position: relative;

    .main-photos__photo {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .main-photos__blur {
      position: absolute;
      top:0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,0.15);
    }

    .main-photos__text-container {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .main-photos__buzzwords-container {
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 3rem;
        font-family: 'Caveat';

        .main-photos__right-buzzword {
          margin: auto;
          //background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.30));
          //background-color: rgba(0, 0, 0, 0.50);
          padding: 1rem;
          z-index: 10;
          color: #FFFFFF;
          text-align: right;
        }

        .main-photos__left-buzzword {
          margin: auto;
          //background-color: rgba(0, 0, 0, 0.50);
          padding: 1rem;
          z-index: 10;
          color: #FFFFFF;
          text-align: left;
        }
      }

      .main-photo__title-container {
        width: 80%;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: end;

        .main-photos__title {
          //background-color: rgba(0, 74, 173, 0.50);
          padding: 1rem;
          z-index: 10;
          color: #FFFFFF;
          font-size: 5rem;
          text-align: end;
          font-family: 'Montserrat';
        }
      }

      .main-photo__apartments {
        width: 100%;
        height: 40%;
        display: flex;
        justify-content: space-between;

        .main-photo__apartments__cell {
          height: 100%;
          text-align: center;
          margin: auto;
          font-size: 8rem;
          cursor: pointer;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          //font-family: 'Caveat';
          font-family: 'SeaweedScript';
          text-decoration: none;

          :hover {
            color: goldenrod;
          }
        }

        .main-photo__apartments__arrow {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 2rem;
          font-weight: bold;
          color: red;

          .main-photo__apartments__text {
            height: 30%;
            width: 50%;
            display: flex;
            align-items: center;
            padding: 1rem;
          }

          .left-arrow {
            animation-name: moving-arrow;
            animation-duration: 1.2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }

          .right-arrow {
            animation-name: moving-arrow;
            animation-duration: 1.2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            rotate: 180deg;
          }

          img {
            height: 3rem;
            width: 3rem;
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main-photos {
    .main-photos__container {
      .main-photos__photo {
        img {
        }
      }

      .main-photos__text-container {
        width: 100%;

        .main-photos__buzzwords-container {
          font-size: 1rem;

          .main-photos__right-buzzword {
          }

          .main-photos__left-buzzword {
          }
        }

        .main-photo__title-container {
          .main-photos__title {
            font-size: 1rem;
          }
        }
        .main-photo__apartments {
          .main-photo__apartments__cell {
            font-size: 2rem;
            :hover {
            }
          }
          .main-photo__apartments__arrow {
            .main-photo__apartments__text {
              font-size: 0.8rem;
            }
            .left-arrow {
            }
            .right-arrow {
            }
            img {
              height: 1rem;
              width: 1rem;
            }
          }
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .main-photos {
    .main-photos__container {
      .main-photos__photo {
        img {
        }
      }

      .main-photos__text-container {
        width: 600px;

        .main-photos__buzzwords-container {
          font-size: 1.5rem;

          .main-photos__right-buzzword {
          }

          .main-photos__left-buzzword {
          }
        }

        .main-photo__title-container {
          .main-photos__title {
            font-size: 1.8rem;
          }
        }
        .main-photo__apartments {
          .main-photo__apartments__cell {
            font-size: 3rem;
            :hover {
            }
          }
          .main-photo__apartments__arrow {
            .main-photo__apartments__text {
              font-size: 1rem;
            }
            .left-arrow {
            }
            .right-arrow {
            }
            img {
              height: 1rem;
              width: 1rem;
              margin: 0 1rem;
            }
          }
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .main-photos {
    .main-photos__container {
      .main-photos__photo {
        img {
        }
      }

      .main-photos__text-container {
        width: 768px;

        .main-photos__buzzwords-container {
          font-size: 2rem;

          .main-photos__right-buzzword {
          }

          .main-photos__left-buzzword {
          }
        }

        .main-photo__title-container {
          .main-photos__title {
            font-size: 2.5rem;
          }
        }
        .main-photo__apartments {
          .main-photo__apartments__cell {
            font-size: 4rem;
            :hover {
            }
          }
          .main-photo__apartments__arrow {
            .main-photo__apartments__text {
              font-size: 1.3rem;
            }
            .left-arrow {
            }
            .right-arrow {
            }
            img {
              height: 2rem;
              width: 2rem;
              margin: 0 1rem;
            }
          }
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main-photos {
    .main-photos__container {
      .main-photos__photo {
        img {
        }
      }

      .main-photos__text-container {
        width: 992px;

        .main-photos__buzzwords-container {
          font-size: 2rem;

          .main-photos__right-buzzword {
          }

          .main-photos__left-buzzword {
          }
        }

        .main-photo__title-container {
          .main-photos__title {
            font-size: 3rem;
          }
        }
        .main-photo__apartments {
          .main-photo__apartments__cell {
            font-size: 5rem;
            :hover {
            }
          }
          .main-photo__apartments__arrow {
            .main-photo__apartments__text {
              font-size: 2rem;
            }
            .left-arrow {
            }
            .right-arrow {
            }
            img {
              height: 2.5rem;
              width: 2.5rem;
              margin: 0 1rem;
            }
          }
        }
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .main-photos {
    .main-photos__container {
      .main-photos__photo {
        img {
        }
      }

      .main-photos__text-container {
        width: 100%;

        .main-photos__buzzwords-container {
          font-size: 4rem;

          .main-photos__right-buzzword {
          }

          .main-photos__left-buzzword {
          }
        }

        .main-photo__title-container {
          .main-photos__title {
            font-size: 4rem;
          }
        }

        .main-photo__apartments {
          font-size: 2rem;
          .main-photo__apartments__cell {
            font-size: 8rem;
            :hover {
            }
          }
          .main-photo__apartments__arrow {
            .main-photo__apartments__text {
              font-size: 2rem;
            }
            .left-arrow {
            }
            .right-arrow {
            }
            img {
              height: 3rem;
              width: 3rem;
              margin: 0 1rem;
            }
          }
        }
      }
    }
  }
}










